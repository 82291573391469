'use strict';

Gri.module({
  name: 'hero-alpha',
  ieVersion: null,
  $el: $('.hero-alpha'),
  container: '.hero-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    let $heroWrapper = $('.hero-alpha-wrapper');
    // endregion

    // region run
    resizeController();
    // endregion

    // region events
    $(window).on('resize', resizeController);
    // endregion

    // region events functions

    // endregion

    // region functions
    function resizeController() {
      let windowW = $(window).width();
      let windowH = $(window).height();
      let headerH = $('.header-alpha').height();

      if(windowW >= 768) {
        $heroWrapper.height(windowH - headerH);
      }
    }
    // endregion

  }
});
